import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				KickIt Arena
			</title>
			<meta name={"description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:title"} content={"KickIt Arena"} />
			<meta property={"og:description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="icon1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link4">
				KickIt Arena
			</Override>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10:33:03.242Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button">
				Забронювати зараз
			</Override>
			<Override slot="text1">
				Ласкаво просимо до KickIt Arena: Ваше прем'єрне місце для футболу
			</Override>
			<Override slot="text3" />
			<Override slot="text2">
				У KickIt Arena ми віримо, що футбол - це більше, ніж просто спорт, це стиль життя. Незалежно від того, організовуєте ви дружній матч, змагання або просто хочете насолодитися грою з друзями, наші сучасні футбольні поля забезпечують ідеальні умови для гри. Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу.
			</Override>
			<Override slot="text" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Високоякісні футбольні поля для кожного гравця
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Наші поля ретельно доглядаються, щоб забезпечити найвищу якість ігрової поверхні. Незалежно від того, чи ви досвідчений професіонал, чи лише починаєте, ви оціните гладкий, добре доглянутий газон, який дозволяє здійснювати точний контроль і рух. Поля також оснащені професійним освітленням, що дозволяє грати в будь-який час дня чи ночі. Крім того, ми пропонуємо кілька розмірів полів, щоб відповідати різним типам ігор, від матчів 5 на 5 до повноцінних 11 на 11.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10:33:03.244Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong
						sm-text-align="left"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Сучасні зручності для неперевершеного досвіду
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому обирають нас?
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Окрім надання місця для гри, KickIt Arena пропонує повний спектр зручностей, щоб забезпечити вам все необхідне. У наших просторих роздягальнях є чисті душі, а також затишна лаунж-зона, де ви можете відпочити до і після гри. Ми також маємо повністю укомплектований спортивний магазин, де ви знайдете всі необхідні аксесуари, від м'ячів до форм. Крім того, наш об'єкт оснащений безкоштовним Wi-Fi, що дозволяє вам залишатися на зв'язку і ділитися своїми футбольними досягненнями миттєво.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Зручне онлайн-бронювання
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Наші поля доступні для оренди за конкурентними цінами, і ми пропонуємо спеціальні пакети для команд і ліг.{" "}
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Спеціальні пропозиції для команд
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Ми пишаємося нашим дружнім персоналом, який завжди готовий забезпечити вам приємний та безтурботний час у нас.{" "}
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Комфортна лаунж-зона
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Незалежно від того, чи ви прийшли на одну гру чи на регулярні тренування, наша відданість якості та сервісу означає, що ви завжди матимете чудовий час як на полі, так і поза ним.{" "}
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Індивідуальний підхід
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Ми також пропонуємо послуги індивідуальних консультацій для команд.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						<Strong>
							Приєднуйтесь до спільноти футбольних ентузіастів
						</Strong>
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						KickIt Arena - це не просто місце для гри, це спільнота футбольних любителів. Ми регулярно проводимо заходи, турніри та ліги, які об'єднують гравців усіх рівнів. Приєднуйтесь до нашої спільноти, щоб зустріти однодумців, покращити свої навички та насолодитися атмосферою, яку створює футбол.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10:33:03.244Z"
					display="block"
					width="100%"
					md-order="-1"
					srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10:33:03.242Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Долучайтеся до нашої спільноти
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Слідкуйте за нами у соціальних мережах, щоб бути в курсі майбутніх подій та приєднуватися до обговорення з іншими шанувальниками футболу. У KickIt Arena кожна гра - це можливість зв'язатися та розвиватися як гравець.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Nav>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link4">
				KickIt Arena
			</Override>
		</Components.Nav>
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});